import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_gg_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import icon1 from "../assets/images/icons/services/Business Bankruptcy.png";
import icon2 from "../assets/images/icons/services/Chapter 13 Bankruptcy .png";
import icon3 from "../assets/images/icons/services/Chapter 7 Bankruptcy.png";
import icon4 from "../assets/images/icons/services/Personal Bankruptcy.png";
import BackgroundImage1 from "../assets/images/photos/firstratebankruptcyattorneys photos/bankrupt_1.webp";
import BackgroundImage2 from "../assets/images/photos/firstratebankruptcyattorneys photos/bankrupt_2.webp";
export default function GgLp1() {
  useLoadScript("../Scripts/Home_gg_lps.js");
  const slides = [
    {
      backgroundImage: BackgroundImage1,
      heading: `Trusted <br/> 
      Bankruptcy <br/>  Attorneys`,
      subheading: `Expert Help to Reclaim Your Financial Freedom`,
    },
    {
      backgroundImage: BackgroundImage2,
      heading: `Trusted <br/> 
      Bankruptcy <br/>  Attorneys`,
            subheading: `Expert Help to Reclaim Your Financial Freedom`,
    },
  ];
  const servicesData = [
    {
      title: "Chapter 7 Bankruptcy",
      content:
        "Chapter 7 Bankruptcy provides immediate relief by wiping out unsecured debts like credit cards and medical bills.",
      imgSrc: icon3,
      number: "01",
    },
    {
      title: "Chapter 13 Bankruptcy",
      content:
        "Chapter 13 Bankruptcy allows debtors to restructure payments while keeping valuable assets like homes.",
      imgSrc: icon2,
      number: "02",
    },
    {
      title: "Business Bankruptcy",
      content:
        "Chapter 11 Bankruptcy, known for business filings, involves liquidating specific assets and discharging all debts.",
      imgSrc: icon1,
      number: "03",
    },
    {
      title: "Personal Bankruptcy",
      content:
        "Personal Bankruptcy offers a legal pathway to clear debts and start afresh for individuals facing financial challenges.",
      imgSrc: icon4,
      number: "04",
    },
  ];
  const contactcnt = {
    Heading: `Secure Your Financial 
Future Today! `,
  };
  const PhoneNumber = {
    PhoneNo: "(877) 495-0162",
  };
  return (
    <div>
 <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slides={slides} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <Services servicesData={servicesData} PhoneNumber={PhoneNumber} />
      <Contact contactcnt={contactcnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
