import  { useEffect } from "react";

const ExternalScriptsLoader = () => {
  useEffect(() => {
    console.log("Component mounted");

    // Function to load the Ringba script
    const loadRingba = () => {
      console.log("Loading Ringba script");
      const script = document.createElement("script");
      script.id = "__script__id__";
      script.type = "text/javascript";
      script.async = true;
      script.src = "//b-js.ringba.com/CAe6602a382b324e719591a5ac35848a9a";
      document.body.append(script);
      console.log("Ringba script added to the document");
    };

    // DOMContentLoaded event equivalent
    const onDOMContentLoaded = () => {
      console.log("DOMContentLoaded event");
      loadRingba();
      console.log("RINGBA LOADED");

      const params = new URLSearchParams(window.location.search);
      const s1 = params.get("s1");
      sessionStorage.setItem("rtkclickid", s1);
      console.log("rtkclickid set to sessionStorage", s1);

      window._rgba_tags = window._rgba_tags || [];
      window._rgba_tags.push({ clickID: s1 });
      console.log("clickID pushed to _rgba_tags", s1);
    };

    // Trigger the DOMContentLoaded logic
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    } else {
      onDOMContentLoaded();
    }

    // Cleanup function
    return () => {
      console.log("Component unmounted, cleaning up");
      document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
    };
  }, []);

  return null; // No UI component needed, only script loading
};

export default ExternalScriptsLoader;
