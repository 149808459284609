import React from "react";
import imageSrc from '../assets/images/photos/firstratebankruptcyattorneys photos/business-people-discussing-cafe.webp'
export default function Contact({ PhoneNumber ,contactcnt }) {
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
// useEffect (()=>{

// }, [cardRefs])
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="contact-section">
      <div className="contact-content">
        <h2 className="contact-heading">{contactcnt.Heading}</h2>
        <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
      </div>
      <div className="contact-image-container">
        <img src={imageSrc} alt="Contact" className="contact-image" />
      </div>
    </div>
  );
}
