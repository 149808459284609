import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";



export default function Services({ PhoneNumber,servicesData  }) {
  const cardRefs = useRef([]);

  useEffect(() => {
    cardRefs.current.forEach((ref) => {
      if (ref) {
        scrollAnimation.observeElement(ref);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => number.replace(/\D/g, "");
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="services-container">
      <div className="services-heading">
        {/* <div className="sub-title">Our Services</div> */}
        <h3 className="section-title">Our Services</h3>
        <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
      </div>
      <div className="services-cards">
        {servicesData.map((service, index) => (
          <div
            key={service.number}
            ref={(el) => (cardRefs.current[index] = el)}
            className="service-card"
          >
            <figure className="service-icon">
              <img src={service.imgSrc} alt={service.title} />
            </figure>
            <div className="service-content">
              <h4 className="service-title">{service.title}</h4>
              <p className="service-description">{service.content}</p>
              
              <div className="service-number">{service.number}</div>
            </div>
          </div>
        ))}
        
      </div>
    </div>
  );
}
