import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import icon1 from "../assets/images/icons/chooseUs/free and easy.png";
import icon2 from "../assets/images/icons/chooseUs/personalized service.png";
import icon3 from "../assets/images/icons/chooseUs/trusted network.png";
const stepsData = [
  {
    id: 1,
    title: "Trusted Network",
    description:
      "Our extensive network includes only the highest-rated bankruptcy attorneys.",
    icon: icon3,
  },
  {
    id: 2,
    title: "Personalized Service",
    description:
      "We tailor our recommendations to fit your unique financial situation and legal needs.",
    icon: icon2,
  },
  {
    id: 3,
    title: "Free & Easy",
    description:
      "Our referral service is completely free and designed to make finding an attorney effortless.",
    icon: icon1,
  },
];

export default function WhyChooseUs({ PhoneNumber }) {
  // const cardRefs = stepsData.map(() => useRef(null));

  // useEffect(() => {
  //   cardRefs.forEach((ref) => {
  //     if (ref.current) {
  //       scrollAnimation.observeElement(ref.current);
  //     }
  //   });
  // }, []);
  const formatPhoneNumberForTel = (number) => number.replace(/\D/g, "");
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="why-choose-us-container">
      <div className="why-choose-us-left">
        {/* <div className="sub-title">Our Process</div> */}
        <h2 className="main-title">Why choose us?</h2>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
        </div>
      </div>
      <div className="steps-container">
        {stepsData.map((step, index) => (
          <div className="step-card" key={step.id}>
            <div className="card-flex">
              <img src={step.icon} alt={step.title} className="step-icon" />
            </div>
            <div className="catd-flex">
              <h4 className="step-title">{step.title}</h4>
              <p className="step-description">{step.description}</p>
            </div>
          </div>
        ))}
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
        </div>
      </div>
    </div>
  );
}
