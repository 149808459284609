import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import icon1 from "./assets/images/icons/services/Business Bankruptcy.png";
import icon2 from "./assets/images/icons/services/Chapter 13 Bankruptcy .png";
import icon3 from "./assets/images/icons/services/Chapter 7 Bankruptcy.png";
import icon4 from "./assets/images/icons/services/Personal Bankruptcy.png";
import BackgroundImage1 from "./assets/images/photos/firstratebankruptcyattorneys photos/Bankrupt.webp";
import BackgroundImage2 from "./assets/images/photos/firstratebankruptcyattorneys photos/bankrupt_4.webp"; 
export default function landingPage() {
  
  const slides = [
    {
      backgroundImage: BackgroundImage1,
      heading: "Looking For The <br/> Right <br/> Bankruptcy Attorney?",
      subheading: "Connect with the Best in Your Area Now",
    },
    {
      backgroundImage: BackgroundImage2,
      heading: "Looking For The <br/> Right <br/> Bankruptcy Attorney?",
      subheading: "Connect with the Best in Your Area Now",
    },
  ];
  const servicesData = [
    {
      title: "Chapter 7 Bankruptcy",
      content: "Chapter 7 Bankruptcy provides immediate relief by wiping out unsecured debts like credit cards and medical bills. Our network of local attorneys can assess your financial situation and recommend the best course of action.",
      imgSrc: icon3,
      number: "01",
    },
    {
      title: "Chapter 13 Bankruptcy",
      content: "Chapter 13 Bankruptcy allows debtors to restructure payments while keeping valuable assets like homes. Get connected with professionals who can evaluate your debts and tailor a bankruptcy plan that suits your needs.",
      imgSrc: icon2,
      number: "02",
    },
    {
      title: "Business Bankruptcy",
      content: "Chapter 11 Bankruptcy, known for business filings, involves liquidating specific assets and discharging all debts. Discover how our network connects you with local bankruptcy attorneys who specialize in these services.",
      imgSrc: icon1,
      number: "03",
    },
    {
      title: "Personal Bankruptcy",
      content: "Personal Bankruptcy offers a legal pathway to clear debts and start afresh for individuals facing financial challenges. Let us connect you with experienced bankruptcy attorneys in your area who can guide you through this process",
      imgSrc: icon4,
      number: "04",
    },
  ];
  const contactcnt = {
    Heading: `Find Clarity and Confidence with the Right Attorney
        Start Your Free Consultation Today  `
  }
  const PhoneNumber = {
    PhoneNo: "(888) 315-0449",
  };

  return (
    <div className="lp">
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection slides={slides} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <Services servicesData={servicesData} PhoneNumber={PhoneNumber} />
      <Contact contactcnt={contactcnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
