import React, { useEffect, useRef, useState } from "react";


export default function HeroSection({ PhoneNumber , slides}) {
  const heroSectionRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);


  useEffect(() => {
    const loadingAnimationTime = 2000;

    const showMainAnimation = (parent) => {
      const boxContainer = document.createElement("div");
      boxContainer.style.overflow = "hidden";
      const box = document.createElement("div");
      box.classList.add("box", "flex");
      parent.appendChild(boxContainer);
      boxContainer.appendChild(box);
    };

    const revealCurtain = (parent) => {
      const curtain = document.createElement("div");
      curtain.classList.add("flex", "curtain");
      parent.appendChild(curtain);
      const progressBar = document.createElement("div");
      progressBar.classList.add("progressBar");
      curtain.appendChild(progressBar);
      progressBar.classList.add("progressGrow-animation");
      return curtain;
    };

    const init = () => {
      const container = heroSectionRef.current;
      const curtain = revealCurtain(container);
      setTimeout(() => {
        container.removeChild(curtain);
        showMainAnimation(container);
      }, loadingAnimationTime + 100);
    };

    init();

    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div
      ref={heroSectionRef}
      className="hero-section"
      style={{
        backgroundImage: `url(${slides[currentSlide].backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        backgroundRepeat: 'no-repeat',
        transition: "background-image 1s ease-in-out",
      }}
    >
      <div
        className="hero-overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
      ></div>
      <div
        className="hero-content"
        style={{
          position: "relative",
          zIndex: 1,
          color: "#fff",
          textAlign: "center",
          transform: "translateY(-50%)",
        }}
      >

        <h1 className="hero-heading" dangerouslySetInnerHTML={{__html:slides[currentSlide].heading}}/>
        <h2 className="hero-subheading">{slides[currentSlide].subheading}</h2>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`} style={{ color: "#fff", textDecoration: "none" }}>
            CALL NOW: {PhoneNumber.PhoneNo}
          </a>
        </div>
      </div>
    </div>
  );
}
