import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(888) 315-0449",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="373"
        class="elementor elementor-373"
        data-elementor-post-type="page"
      >
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-6fc97aa elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="6fc97aa"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-caa6688"
              data-id="caa6688"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-9558146 elementor-widget elementor-widget-heading"
                  data-id="9558146"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      Disclaimer
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-3d5d595 elementor-widget elementor-widget-text-editor"
                  data-id="3d5d595"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      <strong>Last updated&nbsp;July 25, 2022</strong>
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-d09aa6b elementor-widget elementor-widget-text-editor"
                  data-id="d09aa6b"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The information provided by First Rate Bankruptcy
                      Attorneys (“we,” “us,” or “our”) on{" "}
                      <a
                       
                        href="https://firstratebankruptcyattorneys.com/"
                        class="connectButton"
                      >
                        <b>FirstRateBankruptcyAttorneys</b>
                      </a>{" "}
                      (the “Site”) is for general informational purposes only.
                      All information on the Site is provided in good faith,
                      however we make no representation or warranty of any kind,
                      express or implied, regarding the accuracy, adequacy,
                      validity, reliability, availability, or completeness of
                      any information on the Site. UNDER NO CIRCUMSTANCE SHALL
                      WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
                      RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
                      OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE
                      SITE IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-49cf7e3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="49cf7e3"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae7d72c"
              data-id="ae7d72c"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-d48f4a6 elementor-widget elementor-widget-text-editor"
                  data-id="d48f4a6"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <h4 >PROFESSIONAL DISCLAIMER</h4>
                    <p>
                      The Site cannot and does not contain
                      Bankruptcy&nbsp;advice. The Bankruptcy information is
                      provided for general informational and educational
                      purposes only and is not a substitute for professional
                      advice. Accordingly, before taking any actions based upon
                      such information, we encourage you to consult with the
                      appropriate professionals. We do not provide any kind
                      of&nbsp;Bankruptcy&nbsp;advice.&nbsp;THE USE OR RELIANCE
                      OF ANY INFORMATION CONTAINED ON&nbsp;THE SITE&nbsp;IS
                      SOLELY AT YOUR OWN RISK.
                    </p>
                    <h4>TCPA DISCLAIMER</h4>
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by First
                      Rate Bankruptcy Attorneys, our agents, representatives,
                      affiliates, or anyone calling on our behalf. This is for
                      any purposes relating to your request for Bankruptcy
                      service, at any telephone number, or physical or
                      electronic address you provide or at which you may be
                      reached. You agree we may contact you in any way,
                      including SMS messages (text messages), calls using
                      prerecorded messages or artificial voice, and calls and
                      messages delivered using auto telephone dialing system or
                      an automatic texting system.
                    </p>
                    <p>
                      <span>
                        Automated messages may be played when the telephone is
                        answered whether by you or someone else. In the event
                        that an agent or representative of FIRST RATE BANKRUPTCY
                        ATTORNEYS calls, he or she may also leave a message on
                        your answering machine, voice mail, or send one via
                        text. You certify that the telephone numbers that you
                        have provided, are in fact your contact numbers, and you
                        agree to receive calls at each phone number you have
                        provided FIRST RATE BANKRUPTCY ATTORNEYS. You agree to
                        promptly alert FIRST RATE BANKRUPTCY ATTORNEYS in the
                        event that you stop using a particular phone number.
                        Your cell/mobile telephone provider will charge you
                        according the type of account you carry with those
                        companies. You may opt out of any future contact via
                        text message by replying anytime with “STOP”. You agree
                        that FIRST RATE BANKRUPTCY ATTORNEYS may contact you by
                        email, using any email address you have provided or that
                        you provide in the future. You may opt out of any future
                        contact via email message by replying anytime with
                        “UNSUBSCRIBE”. FIRST RATE BANKRUPTCY ATTORNEYS may
                        listen to and/or record calls between you and any
                        representative without notice, as permitted by
                        applicable laws. For example, we listen to calls to
                        monitor for quality purposes.
                      </span>
                      <br />
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
